import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { decrement, increment } from "../Feature/counterSlice";

export default function Counter() {
  let counter = useSelector((state) => state.counter.value);
  const test = useSelector((state) => state.counter.test);
  const dispatch = useDispatch();

  return (
    <div>
      {test}
      <h1>{counter}</h1>
      <button onClick={() => dispatch(increment())}>Increment</button>
      <button onClick={() => dispatch(decrement())}>Decrenent</button>
    </div>
  );
}
