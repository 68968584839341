import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../Feature/postSlice";

export default function Post() {
  const dispatch = useDispatch();
  const posts = useSelector((state) => state.posts.posts);
  const status = useSelector((state) => state.posts.status);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchData());
    }
  }, [status, dispatch]);

  //   console.log(posts, "ini posts");

  return (
    <div>
      <h1>List Posts</h1>
      {status == "loading" && <div>loading...</div>}
      {status == "successed" &&
        posts.map((post, i) => (
          <article>
            <h3>{post.title}</h3>
            <p>{post.body}</p>
          </article>
        ))}
    </div>
  );
}
