import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "../Feature/counterSlice";
import postReducer from "../Feature/postSlice";

export default configureStore({
  reducer: {
    counter: counterReducer,
    posts: postReducer,
  },
});
