import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./page/Home";
import About from "./page/About";
import Counter from "./page/Counter";
import Post from "./page/Post";
// import Card from "./components/ClassComponent";
// import logo from "./logo.svg";
// import Functional from "./components/Functional";

function App() {
  //react - router
  return (
    <>
      <Routes>
        <Route element={<Home />} path="/" />
        <Route element={<About />} path="/about" />
        <Route element={<Counter />} path="/counter" />
        <Route element={<Post />} path="/post" />
      </Routes>
    </>
  );

  //react - stete, props, hooks
  // // const title = "Latihan 1";
  // return (
  //   <>
  //     <Functional judul="React hooks" />
  //     {/* <Functional judul="Ini komponen 1" jenis="Functional" /> */}
  //     {/* <Card datatitle={title} /> */}
  //   </>
  // );

  // const isDisplay = true;
  // const isLoding = true;
  // const name = "Agus Santoso";
  // const students = [
  //   { name: "Budi", age: 20 },
  //   { name: "Amel", age: 19 },
  //   { name: "Charles", age: 16 },
  //   { name: "Kante", age: 30 },
  // ];
  // if (isLoding) {
  //   return <h1>Load data...</h1>;
  // }
  // return (
  //   <>
  //     <h1>{name}</h1>
  //     <div className="" onClick="(() => {})">
  //       <ol>
  //         {students.map((student, index) => (
  //           <li key={index}>
  //             Name : {student.name}, Age : {student.age}
  //           </li>
  //         ))}
  //       </ol>
  //     </div>
  //     <div>{isDisplay && <h2>Display Data</h2>}</div>
  //     <div>{isDisplay ? <h2>Display true</h2> : <h2>Display false</h2>}</div>
  //   </>
  // );
}

export default App;
