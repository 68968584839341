import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

export default function About() {
  return (
    <div>
      <h1>Ini halaman about</h1>
      <Link to="/">Kembali ke Home</Link>
      <div className="d-flex justify-content-center">
        <Button color="primary">Click Me</Button>
      </div>
    </div>
  );
}
