import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

export default function Home() {
  const [users, setUsers] = useState([]);

  async function loadData() {
    // const res = await fetch("https://jsonplaceholder.typicode.com/users");
    // const data = await res.json();

    //with axios
    const data = await axios.get("https://jsonplaceholder.typicode.com/users");
    setUsers(data.data);
  }

  //   useEffect(() => {
  //     loadData();
  //   }, []);

  return (
    <div>
      <h1>Ini halaman home</h1>
      <div>
        <ol>
          {users.map((user, i) => (
            <li key={i}>{user.name}</li>
          ))}
        </ol>
      </div>
      <button onClick={loadData}>Fetch data</button>
      <Link to="/about">ke About</Link>
    </div>
  );
}
